// Routes definition

import chunks from './chunks' // Builder will get .client or .server

const routes = [
  { path: '/', component: chunks['index'] },
  { path: '/notes', component: chunks['notes'] },
]

export default routes
