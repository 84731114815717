import { useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { withRouter } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import { MDXProvider } from '@mdx-js/react'
import { useStore } from './medux/react'
import { connectReduxDevTools } from './medux/redux-devtools'

import routes from './routes'
import { createState, actions } from './state'
import renderRoutes from './utils/renderRoutes'

import CodeBlock from 'features/CodeBlock'
import { Link as ScrollLink } from 'react-scroll'

const Dot = () => <div className="inline-block mx-1">{'\u2022'}</div>

const Header = () =>
  <header className="app-header">
    <div className="flex-grow text-bold">
      <NavLink to="/" exact>
        <svg className="icon logo-icon" style={{
          padding: '0 7px 0 7px',
          width: '34px',
          marginRight: '5px',
          marginBottom: '-2px'
        }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.29 55.48">
          <path d="M81.38,36.24C77.63,45.4,67.2,54.71,62.57,55.48c.91-.2,5.81-4.47,8.74-8.3a42.17,42.17,0,0,0,7-13.38c3-10.15.74-18.7-5.37-25.22-6.7-7.15.41-12.39,6.53-5,2.16,2.61,3.73,7.1,4.47,11.76a44.41,44.41,0,0,1-2.56,20.9"/>
          <path d="M2.9,19.25C6.66,10.08,17.09.77,21.72,0,20.81.21,15.91,4.48,13,8.3A42.22,42.22,0,0,0,6,21.69c-3,10.14-.74,18.69,5.37,25.22,6.69,7.15-.41,12.39-6.53,5C2.66,49.29,1.09,44.81.35,40.14A44.49,44.49,0,0,1,2.9,19.25"/>
          <path d="M26.34,10.41,40.83,8a41.71,41.71,0,0,1,5.55,13.59,102.6,102.6,0,0,1,6.14-8.41,19.32,19.32,0,0,1,4.59-4.21A7.94,7.94,0,0,1,61,8a4.73,4.73,0,0,1,3.51,1.24,4.68,4.68,0,0,1,1.22,3.39,4.55,4.55,0,0,1-1.22,3.27,4.07,4.07,0,0,1-3.06,1.27,10.83,10.83,0,0,1-3-.51A10.1,10.1,0,0,0,56,16.1a5.21,5.21,0,0,0-3.31,1.25q-2.16,1.7-5.31,7.26Q50.85,37.06,53,39.81a3.38,3.38,0,0,0,2.52,1.61,3.07,3.07,0,0,0,1.87-.59A24,24,0,0,0,61,36.07l1.53.9c-2.42,4.08-4.8,7-7.1,8.63a8.91,8.91,0,0,1-5.22,1.94,7.68,7.68,0,0,1-4.4-1.19,10,10,0,0,1-3.13-3.87,64.61,64.61,0,0,1-3.23-8.59q-4.86,6.2-7.63,9a18,18,0,0,1-4.6,3.72,9,9,0,0,1-3.91.88,4.64,4.64,0,0,1-3.43-1.25,4.36,4.36,0,0,1-1.27-3.24A4.65,4.65,0,0,1,20,39.57a4.71,4.71,0,0,1,3.49-1.38A5.73,5.73,0,0,1,26,38.9,7.85,7.85,0,0,0,29,40a4.54,4.54,0,0,0,2.17-.51,12.51,12.51,0,0,0,3.16-2.74c.78-.87,2.21-2.73,4.29-5.56q-4-14.72-6.26-17.62a4.35,4.35,0,0,0-3.6-1.85A13.6,13.6,0,0,0,26,12Z"/>
        </svg>
      Expreva
      </NavLink>
    </div>
    <div className="text-small app-header-links">
      { process.env.NODE_ENV==='development' &&
        <NavLink to="/notes">Notes</NavLink>
      }
    </div>
  </header>

const components = {
  Helmet,
  wrapper: ({ children }) =>
    <article className="px-3 pb-3"  children={children} />
  ,
  pre: CodeBlock,
  a: props => {
    const { href, children } = props
    if (href && href[0]==='#') {
      return <ScrollLink
        containerId="scroll-container"
        activeClass="active"
        to={href.substr(1)}
        offset={-20}
        smooth={true}
      >
        {children}
      </ScrollLink>
    }
    return <a {...props} />
  }
}

const meta = {
  defaultTitle: 'Expreva',
  titleTemplate: '%s | Expreva',
  htmlAttributes: { lang: 'en' },
  meta: [
    { name: 'description', content: 'A programming language ' }
  ],
  link: [
    { rel: 'shortcut icon', type: 'image/png', href: '/expreva-logo-small.png' },
  ]
}

const App = ({
  store,
  location, // { pathname, search, hash }
}) => {

  useStore({ store })

  useEffect(() => {
    connectReduxDevTools(store)
    window.app = App
  }, [])

  // During server-side render, App.getState is used to hydrate client state
  Object.assign(App, store)

  const bodyClassName = `app-route${
    location.pathname==='/' ? '--home' : location.pathname.replace(/\//g, '--')
  }`

  return <MDXProvider components={components}>
    <Helmet {...{
      ...meta,
      bodyAttributes: {
        className: bodyClassName
      }
    }} />
    <Header />
    <main className="app-main">
      { renderRoutes(routes, store) }
    </main>
  </MDXProvider>
}

export default withRouter(App)
